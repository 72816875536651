#loading {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: unset;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 2;
  background-color: hsl(230, 12%, 10%);
}

#loading .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(230, 12%, 10%);
  padding: 60px;
  display: grid;

  background-image: radial-gradient(ellipse at 50% 0%, #2b75b247 0%, rgba(225, 91, 151, 0) 30%), radial-gradient(ellipse at 100% 0%, #1e293a 0%, rgba(132, 19, 134, 0) 60%);
  background-size: 400% 150%;
  background-position: 0% 0%;
  -webkit-animation: bg-gradient-anim-1 30s linear infinite;
  animation: bg-gradient-anim-1 30s linear infinite;
}

#loading .bg .containment {
  width: 100%;
  max-width: 839px;
  align-self: center;
  justify-self: center;
}

#loading .bg .containment .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: unset;
}

#loading .text {
  position: absolute;
  bottom: 7rem;
  left: 0;
  width: 100%;
  display: grid;
  grid-gap: 2rem;
  justify-content: center;
}

#loading .text .version {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}

#loading .text .status {
  font-size: 14px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-gap: 1rem;
  align-items: center;
  width: max-content;
}

#loading .text .status.error {
  grid-template-columns: 1fr;
  width: auto;
}

#loading .logo-feature {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}

#loading .logo-feature .device {
  position: relative;
  width: 96px;
  pointer-events: none;
}

#loading .logo-feature .device svg {
  fill: #647736;
  opacity: 0.8;
  width: 100%;
  height: auto;
  animation: fadein 0.6s;
}

@keyframes loading-beacon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 0.8;
    transform: scale(1);
  }
}

#loading .logo-feature .device::before,
#loading .logo-feature .device::after {
  content: ' ';
  position: absolute;
  top: -70px;
  left: -70px;
  width: 236px;
  height: 236px;
  border-radius: 100%;
  opacity: 0;
  box-shadow: inset 0 0 0 1px #51b3fe;
  animation: loading-beacon 2s cubic-bezier(0.65, 0.05, 0.36, 1) 0.75s infinite;
}

#loading .logo-feature .device::after {
  box-shadow: inset 0 0 0 1px #51b3fe;
  animation: loading-beacon 2s cubic-bezier(0.65, 0.05, 0.36, 1) 0s infinite;
}

@media screen and (orientation: portrait) {
}

@media screen and (max-width: 600px) {
  #loading .logo-feature .device {
    width: 96px;
    font-size: 96px;
    line-height: 96px;
  }

  #loading .logo-feature .device::before,
  #loading .logo-feature .device::after {
    width: 186px;
    height: 186px;
    top: -45px;
    left: -45px;
  }
}
