.informations {
  padding: 1.5em 0;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.01);
  /* border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1); */
}
.informations .documentation {
  margin: 0 15px;
}
.informations .methods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}
.informations .methods .op {
  width: 100%;
}
.informations .methods .select {
  background-color: hsla(0, 0%, 100%, 0.03);

  cursor: pointer;
  padding: 2rem;
  border-radius: 8pt;
  text-align: center;
}
.informations .methods .select .name {
  font-size: 18px;
  letter-spacing: 1px;
}
.informations .methods .select .image svg {
  width: 75px;
  height: 75px;
}
@media (max-width: 768px) {
  .informations {
    padding: 1.5em 2em;
  }
  .informations .methods {
    grid-template-columns: 1fr;
  }
}
