.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 3;
  margin: calc(25px + env(safe-area-inset-top)) 0;
}
@media (max-width: 768px) {
  .Header {
    position: absolute;
  }
}
