.group.tip {
  position: relative;
  width: 100%;
  left: 0;

  background-color: hsla(0, 0%, 100%, 0.1);
}
.group.tip .bind {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3rem;
  gap: 3rem;
  grid-template-columns: repeat(4, 1fr);
  padding: 3rem;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  overflow: hidden;
}
.group.tip .bind .col {
  position: relative;
  grid-column: 2/4;
  display: grid;
  grid-template-columns: -webkit-max-content minmax(100px, 170px);
  grid-template-columns: max-content minmax(100px, 170px);
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 3px;
  column-gap: 3px;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}
.group.tip .icon {
  position: absolute;
  right: 8rem;
  top: -5rem;
  height: calc(100% + 10rem);
  opacity: 0.5;
  pointer-events: none;
}
.group.tip .icon svg {
  width: auto;
  height: 100%;
}
.group.tip .text .name {
  position: relative;
  font-size: 28px;
  font-weight: 500;
  margin: 4px 0 1rem;
}
.group.tip .text .name:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: -8px;
  width: 48px;
  height: 3px;
  background-color: #fff;
  opacity: 0.2;
}
.group.tip .text .description p {
  max-width: 500px;
  font-size: 16px;
}
@media (max-width: 768px) {
  .group.tip .bind,
  .group.tip .bind .col {
    grid-template-columns: 1fr;
  }
}
