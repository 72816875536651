.spinner {
  position: relative;
  width: 3rem;
  height: 3rem;
  opacity: 0.5;
  pointer-events: none;
}

.spinner.mini {
  width: 1.5rem;
  height: 1.5rem;
}

.spinner svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner svg polygon {
  fill: #fff;
}

.spinner svg .a {
  animation: spinner_bits_a 4s linear infinite;
}

.spinner svg .b {
  animation: spinner_bits_b 4s linear infinite;
}

.spinner svg .c {
  animation: spinner_bits_c 4s linear infinite;
}

.spinner svg .d {
  animation: spinner_bits_d 4s linear infinite;
}

.spinner svg .e {
  animation: spinner_bits_e 4s linear infinite;
}

.spinner svg .f {
  animation: spinner_bits_f 4s linear infinite;
}

.spinner svg:last-child .a {
  animation: spinner_bits_a 2.2s linear infinite;
}

.spinner svg:last-child .b {
  animation: spinner_bits_b 2.2s linear infinite;
}

.spinner svg:last-child .c {
  animation: spinner_bits_c 2.2s linear infinite;
}

.spinner svg:last-child .d {
  animation: spinner_bits_d 2.2s linear infinite;
}

.spinner svg:last-child .e {
  animation: spinner_bits_e 2.2s linear infinite;
}

.spinner svg:last-child .f {
  animation: spinner_bits_f 2.2s linear infinite;
}

@keyframes spinner_bits_a {
  0% {
    opacity: 0;
  }
  14.28571428571429% {
    opacity: 1;
  }
  28.57142857142857% {
    opacity: 0;
  }
  42.85714285714286% {
    opacity: 0;
  }
  57.14285714285715% {
    opacity: 0;
  }
  71.42857142857144% {
    opacity: 0;
  }
  85.71428571428573% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes spinner_bits_b {
  0% {
    opacity: 0;
  }
  14.28571428571429% {
    opacity: 0;
  }
  28.57142857142857% {
    opacity: 1;
  }
  42.85714285714286% {
    opacity: 0;
  }
  57.14285714285715% {
    opacity: 0;
  }
  71.42857142857144% {
    opacity: 0;
  }
  85.71428571428573% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes spinner_bits_c {
  0% {
    opacity: 0;
  }
  14.28571428571429% {
    opacity: 0;
  }
  28.57142857142857% {
    opacity: 0;
  }
  42.85714285714286% {
    opacity: 1;
  }
  57.14285714285715% {
    opacity: 0;
  }
  71.42857142857144% {
    opacity: 0;
  }
  85.71428571428573% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes spinner_bits_d {
  0% {
    opacity: 0;
  }
  14.28571428571429% {
    opacity: 0;
  }
  28.57142857142857% {
    opacity: 0;
  }
  42.85714285714286% {
    opacity: 0;
  }
  57.14285714285715% {
    opacity: 1;
  }
  71.42857142857144% {
    opacity: 0;
  }
  85.71428571428573% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes spinner_bits_e {
  0% {
    opacity: 0;
  }
  14.28571428571429% {
    opacity: 0;
  }
  28.57142857142857% {
    opacity: 0;
  }
  42.85714285714286% {
    opacity: 0;
  }
  57.14285714285715% {
    opacity: 0;
  }
  71.42857142857144% {
    opacity: 1;
  }
  85.71428571428573% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes spinner_bits_f {
  0% {
    opacity: 0;
  }
  14.28571428571429% {
    opacity: 0;
  }
  28.57142857142857% {
    opacity: 0;
  }
  42.85714285714286% {
    opacity: 0;
  }
  57.14285714285715% {
    opacity: 0;
  }
  71.42857142857144% {
    opacity: 0;
  }
  85.71428571428573% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
