.button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
  min-width: 175px;
  height: 38px;
  padding: 0 15px;

  letter-spacing: 0.2px;
  font-size: 16px;

  user-select: none;

  color: var(--text-white);
  background-color: hsla(0, 0%, 100%, 0.05);
  /* backdrop-filter: blur(10px); */
  border-radius: 10px;

  transition: opacity 0.25s, color 0.25s, background-color 0.25s, box-shadow 0.25s;
}
.button:before,
.button:after {
  position: absolute;
  pointer-events: none;
  z-index: 3;
  border-radius: 10px;
}
.button:before {
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: transparent;
  box-shadow: inset 0 0 0 1px transparent;

  transition: box-shadow 0.25s, background-color 0.25s;
}
.button:after {
  content: ' ';
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);

  box-shadow: 0 0 0 2px transparent;

  transform: scale(1.021) translateZ(0);
  transition: box-shadow 0.25s, transform 0.25s;
}

.button:hover:before {
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.135);
  background-color: unset;
}

.button:hover:after {
  box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.135);
  transform: scale(1) translateZ(0);
}

.button.cta,
.button.status {
  height: 50px;
  letter-spacing: 0.5px;
  /* backdrop-filter: blur(10px) brightness(1.4); */
}

.button.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.button.cta {
  justify-content: space-between;
  grid-auto-flow: column;
  padding: 0 50px;

  font-size: 19px;
}

.button.cta > div {
  /* font-family: var(--font-bold); */
  margin: auto;
  padding: 4px 0 5px;
  height: fit-content;
  color: var(--text-white);
}

.button.select {
  flex-direction: column;
  width: unset;
  max-width: unset;
  min-width: unset;
  height: unset;
  background-color: unset;
  /* backdrop-filter: unset; */
}

.button.status {
  font-weight: 500;
  text-shadow: 0 0 20px #000;
  justify-content: center;
  text-transform: uppercase;
  background-color: hsl(220deg 1% 59% / 75%);
  border-radius: 8px;
}
@media only screen and (max-width: 768px) {
  .button.status {
    background-color: hsl(231deg 11% 13%);
  }
}
