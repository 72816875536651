@charset "UTF-8";

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  /** ///////////// FONTS ////////////// */
  --font-display: 'Neue Haas Grotesk Display', sans-serif;
  --font-logo: 'Volte', sans-serif;
  --font-text: 'Volte Text', sans-serif;
  --font-sub: 'Volte Subheading', 'Neue Haas Grotesk Display', sans-serif;
  --font-bold: 'Volte Bold', sans-serif;

  /** ///////////// COLORS ////////////// */
  --text-white: hsl(240, 24%, 96%);
  --text-description-light: hsl(218, 24%, 60%);
  --text-description-dark: hsl(240, 2%, 61%);
  --text-description-ulight: hsl(0, 0%, 80%);

  --bkgd-color: hsl(220, 44%, 17%);

  /** ///////////// SIZE ////////////// */
  --title-min-h: 4.5em;
  --title-max-h: 6.5em;

  --globe-h: 80vh;
  --globe-h-work: 50vh;
  --vw-max: 100vw;
  --vh-max: 100vh;
}

/** ////////////////////////////////// */
/** ///////////// DEF. ////////////// */
/** ///////////////////////////////// */
:focus {
  outline: none;
}

/* html {
  scroll-behavior: smooth;
} */

::selection {
  background: #4db2ff;
  color: #fff;
}

@media (any-pointer: fine) {
  ::-webkit-scrollbar-track {
    background-color: hsl(231, 12%, 15%);
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: hsl(227, 6%, 30%);
    border-radius: 0;
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
}

body {
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Neue Haas Grotesk Display', sans-serif;
  color: hsl(240, 24%, 96%);
  background-color: hsl(230, 12%, 10%);
  line-height: 1;
  width: 100%;
  height: 100%;
  cursor: default;
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

main:after,
main:before {
  content: '';
  width: 100%;
  height: 100%;
  pointer-events: none;
}

main::after {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  /* background-image: url(/static/images/incs/noise.png); */
  background-repeat: repeat;
  background-position: 50% 50%;
}
p {
  line-height: 1.2;
  margin: 0;
}

a {
  color: hsl(0, 0%, 80%);
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
  text-decoration: none;
  cursor: pointer;
}

ol,
ul {
  list-style: none;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

section {
  clear: both;
  padding: 0;
  margin: 0;
}

hr {
  border: 1px solid #3a3a3c;
  margin: 0;
}

i {
  font-family: 'Segoe-MDL2-Assets' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}
