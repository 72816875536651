.page-header {
  position: relative;
  margin: 24px 0 0;
}
.page-header:before {
  top: -24px;
  left: -16px;
  padding: 16px 16px 24px;
  width: 48px;
  height: 100%;
  box-shadow: inset 0 0 0 1px #fff;
}

.page-header:after,
.page-header:before {
  content: ' ';
  position: absolute;
  opacity: 0.2;
}

.page-header:after {
  top: -12px;
  left: 0;
  width: 32px;
  border-bottom: 3px solid #fff;
}

.page-header .name {
  font-size: 48px;
  line-height: 1;
  font-weight: 500;
}

.page-header .sub-name {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 4px;
  margin: 0 0 6px;
  color: hsla(0, 0%, 100%, 0.6);
}
.page-header .description {
  margin: 10px 0 0;
}
.page-header .description p {
  font-size: 16px;
  line-height: 1.4;
  max-width: 400px;
}
@media (max-width: 768px) {
  .page-header .name {
    font-size: 38px;
  }
}
