.HeaderInfo .logoFormat {
  display: block;
  width: fit-content;
  transition: 0.4s;
  pointer-events: all;
}
.HeaderInfo .container {
  margin: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.HeaderInfo .logoFormat > .logo {
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--font-logo);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 40px;
  color: var(--text-white);
  letter-spacing: -2px;
}
.HeaderInfo .logoFormat > .logo span {
  display: block;
  margin: 4px 0 0 5px;
  font-size: 0.5em;
}

.HeaderInfo .email {
  pointer-events: all;
  position: relative;
  display: grid;
  align-content: center;
}
@media (max-width: 768px) {
  .HeaderInfo .email {
    display: none;
  }
  .HeaderInfo .logoFormat > .logo {
    font-size: 30px;
    margin: 0 auto;
  }
  .HeaderInfo .container {
    justify-content: center;
  }
}
