#contact {
  grid-template-rows: 100%;
}
#contact.view {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 248px);
}
#contact.view::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  opacity: 0.3;
  background-image: url(https://mainloop.rs/static/images/incs/board-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  animation: 6s ease-in-out 0s 1 normal none running leaves-who;
}
@-webkit-keyframes leaves-who {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
@keyframes leaves-who {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
#contact .wrapper {
  display: grid;
  height: calc(100% - 40px);
}
#contact .wrapper .bind {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  padding: calc(40px + 6rem) 15px 4rem;
  overflow: hidden;

  margin: auto 0;
}
#contact .box {
  position: relative;
  background-color: hsla(0, 0%, 100%, 0.04);
  width: 100%;
  height: 300px;
  border-radius: 15pt;
  overflow: hidden;
  padding: 4em;
  display: flex;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  justify-content: space-between;
}
#contact .box .main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#contact .box .main-info .node {
  display: flex;
  flex-direction: row;
}
#contact .box .main-info .node .row:first-child {
  margin-right: 30px;
}

#contact .box .main-info .node .row:last-child {
  position: relative;
}
#contact .box .main-info .node .row:last-child::before {
  content: '';
  position: absolute;
  left: -15px;
  bottom: 0;
  width: 1px;
  height: 45px;
  background-color: #98989d;
}
#contact .box .main-info .title {
  font-weight: 600;
  font-family: var(--font-bold);
  line-height: 0.85;
  font-size: 2.5em;
  width: fit-content;
}
#contact .box .main-info .description {
  line-height: 1.2;
  font-size: 17px;
  width: 100%;
  margin-top: 25px;
  color: #98989d;
}
#contact .box .main-info .mail {
  font-size: 28px;
  color: #4fb3ff;
  font-weight: 500;
  font-family: 'Volte Subheading', 'Neue Haas Grotesk Display', sans-serif;
  position: relative;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  letter-spacing: 0.04em;
}
@media (max-width: 768px) {
  #contact .box {
    padding: 3em;
  }
  #contact .box .main-info .node {
    flex-direction: column;
  }
  #contact .box .main-info .node .row:last-child::before {
    display: none;
  }
  #contact .box .main-info .title {
    font-size: 2.3em;
  }
  #contact .box .main-info .mail {
    font-size: 24px;
  }
}
