.ready .bind {
  text-align: center;
  padding: 4rem 0 4.2rem;
}
.ready .title {
  font-size: 3em;
  font-weight: 500;
}
.ready .description {
  margin: 2rem auto;
  max-width: 600px;
  font-size: 18px;
}
.ready .description + a .button {
  margin: 0px auto 10px;
}
.ready em {
  font-size: 13px;
  color: hsla(0, 0%, 100%, 0.3);
}

.ready .clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  place-content: center;
  margin: 15px 0 0;
  gap: 15px;
  text-align: center;
}
@media (max-width: 768px) {
  .ready .bind {
    padding: 2em;
  }
  .ready em {
    width: 80%;
  }

  .ready .clients {
    flex-direction: column;
  }
  .ready .clients a {
    margin: 0 auto;
  }
}
