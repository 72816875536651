.NavLinks {
  position: absolute;
  width: 500px;
  left: 50%;
  top: 37px;
  margin-left: -224px;
  z-index: 4;
  text-align: center;
}
.NavLinks ul li {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.NavLinks ul li a {
  text-transform: uppercase;
  font-family: var(--font-sub);
  font-weight: 600;
  letter-spacing: 0.24em;
  margin: 0 1em;
  color: hsl(240deg 24% 96%);
}
.stickyNav {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000001f;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
}
.stickyNav > .wrapper {
  margin: 0 auto;
  max-width: 1324px;
  height: 60px;
  display: flex;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3rem;
  font-size: 12px;
}
.stickyNav .button {
  position: relative;
  width: 100%;
  margin: 0 15px;
  max-width: 250px;
  height: 38px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.8);
  box-shadow: 2px 0 0 0 #37383d;
  background-color: #36363e00;
  border-radius: 0;
  transition: opacity 0.25s, color 0.25s, background-color 0.25s, box-shadow 0.25s;
}
.stickyNav .button:hover {
  background-color: #36363e55;
}
@media only screen and (max-width: 768px) {
  .NavLinks {
    display: none;
  }
  .stickyNav .button {
    max-width: 150px;
    margin: 0;
  }
}
