@media (max-width: 768px) {
  /** ////////////////////////////////// */
  /** ////////// Mobile Tabs ////////// */
  /** ///////////////////////////////// */
  .mobile-tabs {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 90px;
    width: 100%;
    bottom: 0;
    z-index: 999999;
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
  .mobile-tabs .node {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    background-color: #17181d70;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(20px);
    /* border-top: 2px solid #1c284263; */
    padding: 10px 10px;
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
    bottom: 0;
  }
  .mobile-tabs .node a {
    position: relative;
    text-decoration: none;
    color: #fdfdfd;
    font-family: 'Volte Text', Geneva, Tahoma, sans-serif;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: inline-block;
    width: 100px;
    padding: 0;
    z-index: 2;
    height: 38px;
    margin: auto 0 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: auto 0 0 0;
  }

  .mobile-tabs a b {
    position: relative;
    -webkit-transition: 0.3s ease top;
    -o-transition: 0.3s ease top;
    transition: 0.3s ease top;
    font-family: 'Volte Text';
    text-align: center;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-right: -0.2em;
    margin: 5px 0 0 0;
    font-size: 0.6em;
  }

  .mobile-tabs a i {
    position: relative;
    left: 25%;
    -webkit-transition: 0.3s ease left;
    -o-transition: 0.3s ease left;
    transition: 0.3s ease left;
  }

  .mobile-tabs a:hover b {
    top: 0;
  }

  .mobile-tabs a:hover i {
    left: 0;
  }
  .mobile-tabs a svg {
    transition: 0.3s all;
  }
  .mobile-tabs a svg.active {
    color: #4fb3ff;
    filter: drop-shadow(0 0 15px #4fb3ff);
  }

  .app-footer {
    padding: 30px 30px 100px;
  }
}
