h4 {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 0 0 6px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.1);
  font-size: 18px;
}
h4:nth-child(1) {
  margin: 0;
}

p:last-child {
  margin: 0;
}

button {
  display: block;
  font-weight: 400;
  font-family: inherit;
  -webkit-appearance: none;
  border: none;
  border-radius: unset;
  padding: 5px;
  border-radius: 5pt;
  margin: 0 auto 10px auto;
  height: 2rem;
  text-align: unset;
}

.view {
  width: 100%;
  height: 100%;
  /* min-height: calc(100vh - 248px); */
  display: grid;
  align-items: start;
  align-content: start;
}

.wrapper {
  max-width: 1324px;
  width: 100%;
  padding: 0 3em;
  position: relative;
  margin: 0 auto;
}

.container {
  /* width: 100%; */
  margin: 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bind,
.view .frame {
  width: 100%;
  /* max-width: 1920px; */
  margin: 0 auto;
}

.container.bind {
  /* padding: 3rem 3rem 0; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  gap: 3rem;
  max-width: 1920px;
  margin: 0 auto;
}

/* Menu */
.menu a {
  display: inline-block;
  font-family: var(--font-sub);
  position: relative;
  color: var(--text-white);
  padding: 8px 10px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.04em;
}

.menu a span {
  display: inline-block;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.menu a:after {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  background-color: #e80262;
  left: 50%;
  top: calc(100% - 0.15em);
  width: 0;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.14, 0.8, 0.4, 1);
  -o-transition: all 0.8s cubic-bezier(0.14, 0.8, 0.4, 1);
  transition: all 0.8s cubic-bezier(0.14, 0.8, 0.4, 1);
}

.menu a.hover:after {
  left: 10px;
  width: calc(100% - 20px);
  opacity: 1;
  -webkit-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

@media only screen and (hover: hover) and (pointer: fine) {
  .menu a:hover:after {
    left: 10px;
    width: calc(100% - 20px);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.14, 0.8, 0.4, 1);
    -o-transition: all 0.2s cubic-bezier(0.14, 0.8, 0.4, 1);
    transition: all 0.2s cubic-bezier(0.14, 0.8, 0.4, 1);
    -webkit-box-shadow: 0 0 15px -0.5px #4fb3ff;
    box-shadow: 0 0 15px -0.5px #4fb3ff;
    background: #4fb3ff;
  }
}

.menu.inline-menu li {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}

.view .frame {
  padding: 4rem 3rem 3rem;
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
}

.view .frame.has-header {
  padding: 3rem 0 0;
}

.view .frame .container {
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
  align-content: start;

  justify-content: unset;
}

.hyperlink {
  color: #888888;
}
.hyperlink:before {
  content: ' ';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 0.2;
  transition: 0.3s all;
}

@-webkit-keyframes bg-gradient-anim-1 {
  0%,
  100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 100%;
  }
}
@keyframes bg-gradient-anim-1 {
  0%,
  100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 100%;
  }
}
@keyframes sparkle-horizontal {
  0% {
    background-position: 200% 50%;
  }
  77%,
  to {
    background-position: -200% 50%;
  }
}
@keyframes sparkle-vertical {
  0% {
    background-position: 50% 200%;
  }
  66%,
  to {
    background-position: 50% -200%;
  }
}
@media (max-width: 768px) {
  .container.bind {
    grid-template-columns: 1fr;
  }
  .wrapper {
    padding: 0;
  }
  .view .frame,
  .view .frame.has-header {
    padding: 4rem 2rem 3rem;
  }
}
