.app-footer {
  position: relative;
  color: var(--text-white);
  background-color: hsla(0, 0%, 100%, 0.04);
  padding: 30px;
  z-index: 9;
  overflow: hidden;
}

.app-footer .wrapper {
  position: unset;
}

.app-footer .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app-footer .right {
  margin: 0 0 0 auto;
}
.app-footer .footer-menu {
  margin: 0 0 40px -10px;
}

.app-footer .copyright .note {
  font-family: var(--text-description-dark);
  color: var(--text-description-dark);
}

.app-footer .f-logo-ov {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 695 650.55'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23e5e5ea; %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M484.33,485.93V622.57a349,349,0,0,1-239.21,12.65V239.07L347.5,341.45v190A227.37,227.37,0,0,0,484.33,485.93Z'%3E%3C/path%3E%3Cpath class='cls-1' d='M695,303.05C695,435.1,621.35,550,512.88,608.76V460.57a228.39,228.39,0,0,0,0-315v-1.47L347.25,309.7,182.6,145q-7.72,8.07-14.65,16.86a228.41,228.41,0,0,0,50.59,329.67V625.83C90.47,574.63,0,449.41,0,303.05A346.09,346.09,0,0,1,83.33,77.28c4.82-5.65,9.85-11.16,15-16.48a349.42,349.42,0,0,1,79-60.8l41.2,41.2,129,129v.49L484.33,33.81,518,.18A347.8,347.8,0,0,1,695,303.05Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  width: 34em;
  background-position: center;
  height: 100%;
  position: absolute;
  opacity: 0.02;
  top: 0;
  z-index: -1;
}

.app-footer .social-menu {
  justify-content: flex-end;
  margin-right: -5px;
  margin-bottom: 35px;
}

.app-footer .credit {
  display: flex;
}

.app-footer .version {
  color: var(--text-description-dark);
  margin: auto 10px 8px 0;
}

.app-footer .credit .credit-logo {
  width: fit-content;
  fill: var(--text-white);
  font-family: var(--font-logo);
  margin: 0 auto;
  font-weight: 500;
  font-size: 38px;
  color: var(--text-white);
  letter-spacing: -2px;
}
.app-footer ul {
  display: flex;
  align-items: center;
}
.app-footer .menu.inline-menu li {
  display: flex;
  height: fit-content;
  gap: 5px;
  cursor: pointer;
}

.app-footer .langSelect {
  background-color: #2b2d35;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s box-shadow;
}
.app-footer .langSelect.active {
  box-shadow: 0 0 0 3px #414246;
}

.mobile-tabs {
  display: none;
}
@media (max-width: 768px) {
  .app-footer .social-menu {
    justify-content: unset;
  }
}
