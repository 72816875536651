.info p {
  line-height: 1.6;
  margin: 0 0 0.4rem;
}
.info .module.faq .qa .a .markdown ul {
  margin: 0 0 0 15px;
}

.info .module.faq .qa .a .markdown p {
  padding: 0 0 0 15px;
  overflow-wrap: anywhere;
}
.info .module.faq .qa .a .markdown pre {
  width: 100%;
}

.info .header {
  background-color: hsla(0, 0%, 100%, 0.03);
}

.info .header .bind {
  position: relative;
  padding: calc(6rem + 40px) 1rem 4rem;
  overflow: hidden;
}

/* ----------------------------------- */

.info .wrapper.full {
  max-width: 100%;
  padding: 0;
}
.info .wrapper.full .container {
  margin: 0;
}

.info .features {
  width: 100%;
}

/* ----------------------------------- */

.info .frame .container {
  grid-template-columns: 400px auto;
}

.info .module.overview {
  position: sticky;
  top: 7em;
  height: fit-content;
  overflow: auto;
}
#faq .module.overview {
  margin-left: 15px;
}

.info .module.overview ul li {
  line-height: 1.4;
  font-size: 16px;
}

.info .module.overview ul li:not(:last-child) {
  margin: 0 0 20px;
}
.info .module.faq .qa {
  padding: 20px 0 50px;
}

.info .module.faq .qa:first-child {
  padding: 0 0 30px;
}

.info .module.faq .node {
  position: relative;
}

.info .module.faq .node::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  /* transition: 0.3s box-shadow, 0.3s padding; */
  transition: 0.3s padding;
  border-radius: 8px;
  z-index: -1;
}

.info .module.faq .node.active::before {
  padding: 10px;
  /* box-shadow: 0 0 0 3px #414246; */
  /* transform: translateY(-50%); */
  background: linear-gradient(125deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0) 30%, hsla(0, 0%, 100%, 0.4) 50%, hsla(0, 0%, 100%, 0) 70%, hsla(0, 0%, 100%, 0));
  background-repeat: no-repeat;
  background-size: 200%;
  opacity: 0.5;
  animation: sparkle-horizontal 5s linear 0s infinite reverse forwards;
}

.info .module.faq .qa:last-child {
  margin: 0;
}

.info .module.faq .qa .q {
  color: hsla(0, 0%, 100%, 0.9);
  font-size: 22px;
  font-weight: 600;
  padding: 5px 0 5px 15px;
  background: hsla(0, 0%, 100%, 0.02);
  border-radius: 5pt;
  margin: 0 0 1rem;
}

.info .module.faq .qa .a {
  font-size: 18px;
  max-width: 800px;
  color: hsla(0, 0%, 100%, 0.7);
  user-select: text;
  z-index: 1;
}

.info .module.faq .qa .a .markdown {
  margin: 0 0 1rem;
}
.info .module.faq .qa .a .markdown ul,
.info .module.faq .qa .a .markdown li {
  list-style: unset;
  padding-inline-start: 20px;
}
.info .module.faq .qa .a .markdown img,
.info .module.faq .qa .a .markdown picture {
  display: block;
  width: 100%;
  height: auto;
}
.info .module.faq .qa .a .markdown img {
  border-radius: 5px;
}
.info .module.faq .qa .a .markdown .qr {
  max-width: 300px;
  width: 100%;
}
.info .module.faq .qa .a .markdown picture {
  padding: 15px 0;
  border-bottom: 1px solid hsl(240, 1%, 39%);
}
.info .module.faq .qa .a .markdown picture:first-child {
  padding-top: 0;
}
.info .module.faq .qa .a .markdown picture:last-child {
  padding-bottom: 0;
  border: none;
}
.info .module.faq .qa .a .markdown em {
  padding: 0 5px 2px;
  margin: 0 7px 0 5px;
  font-style: normal;
  border-radius: 5pt;
}
.info .module.faq .qa .a .markdown em.red {
  background-color: hsl(3, 100%, 61%);
}
.info .module.faq .qa .a .markdown em.gray {
  background-color: hsl(240, 1%, 39%);
}
.info .module.faq .qa .a .markdown .codeJson {
  height: 235px;
  user-select: all;
}

@media (max-width: 768px) {
  .info .header .bind {
    padding: calc(6rem + 40px) 2rem 4rem;
  }
  .info .module.overview {
    position: relative;
    top: unset;
    padding-left: 0;
  }
  .info .frame .wrapper {
    position: unset;
  }
  .info .frame .container {
    grid-template-columns: 1fr;
    margin: 0;
  }

  .info .module.faq .qa .a {
    font-size: 16px;
  }
  .info .module.faq .qa .a .markdown p {
    padding: 0;
  }

  .info .module.faq .qa .q {
    padding: 0;
    background: none;
  }
  .info .module.faq .qa .a .markdown ul,
  .info .module.faq .qa .a .markdown li {
    padding-inline-start: 0;
  }
  .info .module.faq .qa .a .markdown pre {
    width: 100vw;
    position: absolute;
    left: 0;
  }
  .info .module.faq .qa.active::before {
    background-size: 450%;
    animation: sparkle-vertical 5s linear 0s infinite infinite forwards;
  }
}
