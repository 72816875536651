#nomatch {
  grid-template-rows: 100%;
}
#nomatch.view {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 248px);
}
#nomatch.view::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
#nomatch .wrapper {
  display: grid;
  height: calc(100% - 40px);
}
#nomatch .wrapper .bind {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  padding: calc(40px + 6rem) 15px 4rem;
  overflow: hidden;

  margin: auto 0;
}
#nomatch .box {
  position: relative;
  background-color: hsla(0, 0%, 100%, 0.04);
  width: 100%;
  height: 300px;
  border-radius: 15pt;
  overflow: hidden;
  padding: 4em;
  display: flex;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  justify-content: space-between;
}
#nomatch .box .main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#nomatch .box .main-info .title {
  font-weight: 600;
  font-family: var(--font-bold);
  line-height: 0.85;
  font-size: 2.5em;
  width: fit-content;
}
#nomatch .box .main-info .description {
  display: block;
  width: 60%;
  line-height: 1.2;
  font-size: 17px;
  margin: 5px 0 20px;
  color: #98989d;
}
#nomatch .box .main-info .mail {
  font-size: 28px;
  color: #4fb3ff;
  font-weight: 500;
  font-family: 'Volte Subheading', 'Neue Haas Grotesk Display', sans-serif;
  position: relative;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  letter-spacing: 0.04em;
}
@media (max-width: 768px) {
  #nomatch .box {
    padding: 3em;
  }
  #nomatch .box .main-info .title {
    font-size: 2.3em;
  }
  #nomatch .box .main-info .mail {
    font-size: 24px;
  }
}
