.features {
  background-color: hsla(0, 0%, 100%, 0.01);
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.features .list {
  padding: 20px 0 20px;
  text-align: center;
}
.features .list ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 127px));
  gap: 30px;

  margin-top: 30px;
  place-content: center;
}
/* @include media('retina', '<breakpoint-tablet') {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 135px));
  } */

/* Buttons */
.features .feature-box {
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.25s, color 0.25s, background-color 0.25s, box-shadow 0.25s;

  /* @include media('retina', '<breakpoint-tablet') {
    max-width: 135px;
    max-height: 135px;
  } */
}
.features .feature-box:hover > div {
  box-shadow: 0px 40px 25px 0 hsla(0, 0%, 100%, 0.02);
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  transform: translateY(-30px);
  will-change: transform;
}

.features .feature-box h5 {
  font-size: 16px;
  margin: 15px 0 0;
  letter-spacing: 0.2px;
}

.features .feature-box .image {
  background-color: hsla(0, 0%, 100%, 0.03);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  box-shadow: 0 15px 20px hsla(0, 0%, 100%, 0.02);
  border-radius: 15px;
  width: 100%;
  height: 131px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: grid;

  /* @include media('retina', '<breakpoint-tablet') {
      height: 135px;
    } */
}

.features img {
  width: auto;
  height: 65.5px;
  fill: white;
  place-self: center;

  /* @include media('retina', '<breakpoint-tablet') {
        width: 135px;
        height: 135px;
      } */
}
